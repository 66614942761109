<div class="RFP max-width">
    <div class="RFP__main">
        <img class="RFP__img" src="../../../assets/login.png" />
    </div>
    <div class="RFP__sidebar">
        <progress-spinner v-if="showLoader"></progress-spinner>

        <form @submit.prevent="onSubmit" v-else-if="!showLoader && isResetPwdIdValid" >
            <p> Hi {{username}}, </p>
            <p> Please provide a new password. </p>
            <input type="hidden" name="Username" :value="userName" />

            <input-container :dirtyForm="$v.$dirty || !passwordCriterionSatisfied">
                <label class="RFP__form-label">New Password</label>

                <div class="RFP__password-hint">
                    <input :type="passwordFieldType" class="input__text RFP__form-input"
                        v-invalid="hasError('newPassword') || !passwordCriterionSatisfied" v-model="newPassword"
                        @paste="disablePaste">

                    <mom-icon class="RFP__view-icon" width="24" height="24" svgIcon="view"
                        @click.native="switchVisiblity('newPassword')"></mom-icon>

                    <mom-popper trigger="click" ref="popper" :arrow="false" @click.native="checkPasswordStrength">

                        <div class="RFP__password-tips m-panel">
                            <strong>Password must include</strong>
                            <ul class="RFP__password-tip-list">
                                <li v-for="hint in passwordHints" :class="hint.satisfied ? 'satisfied' : ''">
                                    <span>{{ hint.text }}</span>
                                </li>
                            </ul>
                        </div>

                        <mom-icon slot="anchor" class="RFP__bulb-icon" width="24" height="24"
                            :key="passwordHint" svgIcon="light-bulb"></mom-icon>
                    </mom-popper>
                </div>

                <mom-error v-if="!$v.newPassword.required">New password is required.</mom-error>
                <mom-error v-else-if="!passwordCriterionSatisfied">Password criterion not satisfied.</mom-error>
            </input-container>

            <input-container :dirtyForm="$v.$dirty" class="RFP__confirm-container">
                <label class="RFP__form-label">Confirm Password</label>

                <div class="RFP__confirm-password">
                    <input :type="confirmPasswordFieldType" class="input__text RFP__form-input"
                        v-invalid="hasError('repeatPassword')" v-model="repeatPassword" @paste="disablePaste">

                    <mom-icon class="RFP__confirm-view-icon" width="24" height="24" svgIcon="view"
                        @click.native="switchVisiblity('confirmPassword')"></mom-icon>
                </div>

                <mom-error v-if="!$v.repeatPassword.sameAs">Passwords must match.</mom-error>

                <div class="RFP__error">
                    <mom-error v-if="areInputsValid() && resetPwdFailure">{{ apiErrorMessage }} </mom-error>
                </div>
            </input-container>

            <button type="submit" class="m-button RFP__button">Continue</button>
        </form>
        <div v-else>
            <p class="RFP__invalidId-text">Link to reset the password is either expired or invalid. Please
                <router-link :to="{ name: 'ForgotPassword' }">generate the link</router-link> again.
            </p>
        </div>
    </div>
</div>
