import VueRouter from 'vue-router';

/**
 * Create an instance of `VueRouter` using `routes`
 * @export
 */
export function makeRouter(routes): VueRouter {
    return new VueRouter({
        routes,
        mode: 'history'
    });
}
