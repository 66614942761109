<div class="forgotpwdform">
    <div class="forgotpwdform__header">Forgot Password?</div>
    <img class="forgotpwdform__img" src="../../../../assets/forgotPassword.png"/>

    <form class="forgotpwdform__form" @submit.prevent="onSubmit">

        <div class="forgotpwdform__msg">Enter your <span class="forgotpwdform__txt-bold">email id</span> to help us identify you.</div>

        <input-container>
            <input class="input__text forgotpwdform__form-input" v-model="hint" type="text" placeholder="example@domain.com"/>
            <mom-error v-if="showError">Something went wrong. Please try again later.</mom-error>
            <mom-error v-if="$v.hint.$error && !$v.hint.required">Please enter valid email id.</mom-error>
        </input-container>

        <div class="forgotpwdform__action-btn">
            <save-button type="submit" class="m-button forgotpwdform__button" @click="onSubmit" :transition="buttonTransitPeriod" @state="$emit('state', $event)" :state="saveState">Submit</save-button>
            <button type="button" class="m-button-smoke forgotpwdform__button" @click="onCancel">Cancel</button>
        </div>
    </form>
</div>
