import * as R from 'ramda';
import Vue, { VNode, VNodeData } from 'vue';

const rejectMomError = R.reject((x: VNode) => !!x.data && (x.data.staticClass || '').includes('form-error-label'));

export default Vue.extend({
    name: 'InputContainer',

    functional: true,

    props: {
        dirtyForm: {
            default: true
        },
        dirty: {
            default: true
        }
    },

    render(h, context) {

        // Is form dirty?
        const dirtyForm = context.props.dirtyForm as boolean;

        // Is dirty check enabled?
        const dirty = context.props.dirty as boolean;

        // If dirty mode is enabled, then show errors only if form is dirty
        // Otherwise, Pass everything
        const children = R.cond([
            // If dirty mode is disabled, then don't do anything
            [() => !dirty, R.identity],

            // If dirty mode enabled and form is dirty, then don't do anything
            [() => dirtyForm, R.identity],

            // Otherwise distill mom error components
            [R.T, rejectMomError]

        ])(context.children);

        // console.log(context.slots());

        const staticClass = 'input-container '.concat(context.data.staticClass || '');
        const data: VNodeData = { ...context.data, staticClass };

        return h('div', data, children);
    }

});
