import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { makeRootInstance } from './app/RootInstance';

import { makeRouter } from './router/helper';
import { makeRoutes } from './router/routes';

import { IconPlugin } from '@monjin/vue-toolkit/plugin';

import {
    Checkbox,
    Popper,
    ProgressSpinner,
    SaveButton
} from '@monjin/vue-toolkit';
import { RippleDirective } from '@monjin/vue-toolkit/helper';

// Form helper components
import MomError from './components/Form/Error.vue';
import InputContainer from './components/Form/InputContainer';
import InputInvalid from './directives/Form/InputInvalid';

import Vuelidate from 'vuelidate';

// Registry for svg icon
const iconRegistry = new Map<string, string | Promise<string>>();

import { addIcons } from './icons';

// Register Vue plugins
Vue.use(VueRouter);
Vue.use(IconPlugin, { registry: iconRegistry });

// Register all app specific icons
addIcons(Vue);

Vue.use(Vuelidate);

Vue.component('mom-error', MomError);
Vue.component('input-container', InputContainer);
Vue.component('save-button', SaveButton);
Vue.component('checkbox', Checkbox);
Vue.component('progress-spinner', ProgressSpinner);
Vue.component('mom-popper', Popper);

Vue.directive('invalid', InputInvalid);
Vue.directive('ripple', RippleDirective);

const routes: RouteConfig[] = makeRoutes();

// Make router
const router = makeRouter(routes);

// Instantiate a Vue.js application with AppComponent and store
const rootInstance = makeRootInstance(router);

// Tslint will cause error if `_` is not prefixed to app
const _app = new Vue(rootInstance);
