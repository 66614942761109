import { VueConstructor } from 'vue/types/vue';

import * as iconsConfig from './assets/icons';

export function addIcons(vue: VueConstructor) {
    for (const key in iconsConfig) {
        if (key) {
            const iconConfig = iconsConfig[key];
            vue.addIcon(iconConfig.name, iconConfig);
        }
    }
}

