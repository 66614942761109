import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { not, required, sameAs } from 'vuelidate/lib/validators';

import { resetPassword } from '../../api/user';
import { hasNumber, hasSpecialChars, hasUppercase, PASSWORD_HINTS, PasswordHint } from '../../utils';

import { Popper } from '@monjin/vue-toolkit';
import WithRender from './ResetPassword.html';

const BUTTON_TRANSIT_PERIOD = 1500;

@WithRender
@Component({
    validations: {
        oldPassword: {
            required
        },
        newPassword: {
            required,
            notSameAs: not(sameAs('oldPassword'))
        },
        repeatPassword: {
            sameAs: sameAs('newPassword')
        }
    }
})
export default class ResetPassword extends Vue {

    public $v: any;

    public $refs: {
        popper: Popper;
    };

    public oldPassword: string = '';
    public newPassword: string = '';
    public repeatPassword: string = '';

    public buttonTransitPeriod: number = BUTTON_TRANSIT_PERIOD;
    public saveState: number = 0;

    public resetPwdFailure: boolean = false;

    private userId: string = '';
    private returnUrl: string = '';

    public passwordCriterionSatisfied = true;

    public passwordHints: PasswordHint[] = [...PASSWORD_HINTS];

    public passwordFieldType: string = 'password';
    public confirmPasswordFieldType: string = 'password';
    public apiErrorMessage: string = '';

    public created() {
        this.userId = this.$route.query.id;
        this.returnUrl = this.$route.query.returnUrl;
    }

    public onSubmit() {

        this.resetPwdFailure = false;

        this.$v.$touch();

        this.checkPasswordStrength();

        if (!this.passwordCriterionSatisfied) {
            this.$refs.popper.show();

            return;
        }

        if (this.$v.$invalid) {
            return;
        }

        this.saveState = 1;

        resetPassword(this.oldPassword, this.newPassword, this.userId, this.returnUrl)
            .then(this.onResetPwdResponse)
            .catch(this.onResetPwdFailure);
    }

    public onSaveButtonState({ newState }) {
        this.saveState = newState;
    }

    public disablePaste(e) {
        e.preventDefault();
    }

    public onTextInput() {
        this.resetPwdFailure = false;
    }

    public hasError(model) {
        return this.$v.$dirty && this.$v[model].$error;
    }

    public switchVisiblity(fieldName: string) {
        if (fieldName === 'newPassword') {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        } else {
            this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
        }
    }

    // tslint:disable-next-line:cyclomatic-complexity
    public checkPasswordStrength() {

        if (this.newPassword.length === 0) {
            return;
        }

        this.passwordHints.map((x) => {
            x.satisfied = false;
        });

        if (this.newPassword.length >= 8 && this.newPassword.length <= 20) {
            this.setPwdCompliance('lengthCheck');
        }

        if (hasUppercase(this.newPassword)) {
            this.setPwdCompliance('upperCaseCheck');
        }

        if (hasNumber(this.newPassword)) {
            this.setPwdCompliance('numberCheck');
        }

        if (hasSpecialChars(this.newPassword)) {
            this.setPwdCompliance('specialCharCheck');
        }

        this.passwordCriterionSatisfied = this.isPwdCompliant();
    }

    private isPwdCompliant() {
        return this.passwordHints.every((x) => x.satisfied);
    }

    private setPwdCompliance(type: string) {
        this.passwordHints.map((x) => {
            if (x.type === type) {
                x.satisfied = true;
            }
        });
    }

    private onResetPwdResponse(res) {

        if (!res || !(res.status >= 200 && res.status < 400)) {

            this.onResetPwdFailure(res);

            return;
        }

        this.saveState = 2;

        setTimeout((() => {
            window.location.href = this.returnUrl;
        }), 5000);
    }

    private onResetPwdFailure(res: any) {

        switch (res.status) {
            case 422:
                res.json().then((x) => this.apiErrorMessage = x.message);
                break;

            default:
                this.apiErrorMessage = 'Something went wrong.';
                break;
        }

        this.saveState = 4;
        this.resetPwdFailure = true;
    }
}
