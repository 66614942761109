import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import WithRender from './CheckMail.html';

@WithRender
@Component({})
export default class CheckMail extends Vue {

    @Prop()
    public email: string;
}
