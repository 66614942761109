import { RouteConfig } from 'vue-router/types/router';

import ForgotPassword from '../views/ForgotPassword/ForgotPassword';
import ResetForgottenPassword from '../views/ForgotPassword/ResetFPassword/ResetFPassword';
import Login from '../views/Login/Login';
import ResetPassword from '../views/ResetPassword/ResetPassword';
import TermsAndConditions from '../views/TermsAndConditions/TermsAndConditions';

export function makeRoutes() {
    const routes: RouteConfig[] = [];

    routes.push({
        path: '/login',
        name: 'Login',
        component: Login
    });

    routes.push({
        path: '/forgot-password/reset/:id',
        name: 'ResetForgottenPasswordWithId',
        component: ResetForgottenPassword
    });

    routes.push({
        path: '/forgot-password/reset',
        name: 'ResetForgottenPassword',
        component: ResetForgottenPassword
    });

    routes.push({
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword
    });

    routes.push({
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
    });

    routes.push({
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions
    });

    return routes;
}
