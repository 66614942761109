import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import CheckMail from './components/CheckMail/CheckMail';
import ForgotPasswordForm from './components/ForgotPasswordForm/ForgotPasswordForm';

import { generateResetFPwdLink } from '../../api/user';

import WithRender from './ForgotPassword.html';

@WithRender
@Component({
    components: {
        'check-mail': CheckMail,
        'forgot-pwd-form': ForgotPasswordForm
    }
})
export default class ForgotPassword extends Vue {

    public isMailSent: boolean = false;

    public email: string = '';

    public saveState: number = 0;

    public onSubmit(email: string) {
        this.saveState = 1;
        this.email = email;

        generateResetFPwdLink(email)
            .then(this.generatePwdLinkSuccess)
            .catch(this.generatePwdLinkFailure);
    }

    private generatePwdLinkSuccess(res) {
        this.saveState = 2;
        this.isMailSent = true;
    }

    private generatePwdLinkFailure(err) {
        this.saveState = 4;
    }

    public onSaveState({ newState }) {
        this.saveState = newState;
    }

    public onCancel() {
        this.$router.push({ name: 'Login' });
    }
}
