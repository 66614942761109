import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { required, sameAs } from 'vuelidate/lib/validators';

import { Popper } from '@monjin/vue-toolkit';
import { resetForgottenPassword, validateResetFPasswordId } from '../../../api/user';
import { envDomain, hasNumber, hasSpecialChars, hasUppercase, PASSWORD_HINTS, PasswordHint } from '../../../utils';
import { SDK_LOGIN_PATH } from '../../../utils/constants';

import WithRender from './ResetFPassword.html';

@WithRender
@Component({
    validations: {
        newPassword: {
            required
        },
        repeatPassword: {
            sameAs: sameAs('newPassword')
        }
    }
})
export default class ResetForgottenPassword extends Vue {

    public $refs: {
        popper: Popper;
    };

    public $v: any;

    public username: string = '';
    public newPassword: string = '';
    public repeatPassword: string = '';
    public isResetPwdIdValid: boolean = false;
    public showLoader: boolean = true;
    public resetPwdFailure: boolean = false;
    public errorMessage: string = '';
    private resetPwdId: string = '';

    public passwordCriterionSatisfied = true;

    public passwordHints: PasswordHint[] = [...PASSWORD_HINTS];

    public passwordFieldType: string = 'password';
    public confirmPasswordFieldType: string = 'password';
    public apiErrorMessage: string = '';

    public created() {
        this.resetPwdId = this.$route.params.id;

        validateResetFPasswordId(this.resetPwdId)
            .then(this.onValidationCallSuccess)
            .catch(this.onValidationCallFailure);
    }

    public onSubmit() {
        this.resetPwdFailure = false;
        this.$v.$touch();

        this.checkPasswordStrength();

        if (!this.passwordCriterionSatisfied) {
            this.$refs.popper.show();

            return;
        }

        if (this.$v.$invalid) {
            return;
        }

        resetForgottenPassword(this.resetPwdId, this.newPassword)
            .then(this.onResetPwdSuccess)
            .catch(this.onResetPwdFailure);
    }

    public areInputsValid() {
        if (this.$v.repeatPassword.$error && !this.$v.repeatPassword.sameAsPassword) {
            this.errorMessage = 'Passwords do not match';
            return false;
        }

        if (this.$v.newPassword.$error && !this.$v.newPassword.required) {
            this.errorMessage = 'Please enter a valid password.';
            return false;
        }

        return true;
    }

    public hasError(model) {
        return this.$v.$dirty && this.$v[model].$error;
    }

    public disablePaste(e) {
        e.preventDefault();
    }

    public switchVisiblity(fieldName: string) {
        if (fieldName === 'newPassword') {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        } else {
            this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
        }
    }

    // tslint:disable-next-line:cyclomatic-complexity
    public checkPasswordStrength() {

        if (this.newPassword.length === 0) {
            return;
        }

        this.passwordHints.map((x) => {
            x.satisfied = false;
        });

        if (this.newPassword.length >= 8 && this.newPassword.length <= 20) {
            this.setPwdCompliance('lengthCheck');
        }

        if (hasUppercase(this.newPassword)) {
            this.setPwdCompliance('upperCaseCheck');
        }

        if (hasNumber(this.newPassword)) {
            this.setPwdCompliance('numberCheck');
        }

        if (hasSpecialChars(this.newPassword)) {
            this.setPwdCompliance('specialCharCheck');
        }

        this.passwordCriterionSatisfied = this.isPwdCompliant();
    }

    private onResetPwdSuccess() {
        window.location.href = envDomain(window.location.hostname) + SDK_LOGIN_PATH;
    }

    private onResetPwdFailure(err: Error) {

        this.apiErrorMessage = err.message;
        this.resetPwdFailure = true;
    }

    private onValidationCallSuccess(res) {
        this.username = res.username;
        this.isResetPwdIdValid = true;
        this.showLoader = false;
    }

    private onValidationCallFailure(err) {
        this.showLoader = false;
    }

    private isPwdCompliant() {
        return this.passwordHints.every((x) => x.satisfied);
    }

    private setPwdCompliance(type: string) {
        this.passwordHints.map((x) => {
            if (x.type === type) {
                x.satisfied = true;
            }
        });
    }
}
