import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import withRender from './TermsAndConditions.html';

@withRender
@Component({})
export default class TermsAndConditions extends Vue {

    public tAndCAccepted: boolean = false;

    public privacyPolicyAccepted: boolean = false;
}
