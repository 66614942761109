<div class="RPW max-width">
    <div class="RPW__main">
        <img class="RPW__img" src="../../assets/login.png" />
    </div>
    <div class="RPW__sidebar">
        <div v-if="saveState !== 2">
            <h3>Please set a new password.</h3>

            <form @submit.prevent="onSubmit" class="RPW__form">

                <input-container :dirtyForm="$v.$dirty">
                    <label class="RPW__form-label">Old Password</label>
                    <input class="input__text RPW__form-input" v-model="oldPassword" @input="onTextInput" type="password" v-invalid="hasError('oldPassword')"/>

                    <mom-error v-if="!$v.oldPassword.required">Old password is required.</mom-error>
                </input-container>

                <input-container :dirtyForm="$v.$dirty || !passwordCriterionSatisfied">
                    <label class="RPW__form-label">New Password</label>

                    <div class="RPW__password-hint">
                        <input :type="passwordFieldType" class="input__text RPW__form-input"
                            v-invalid="hasError('newPassword') || !passwordCriterionSatisfied" v-model="newPassword"
                            @paste="disablePaste"
                            @input="onTextInput">

                        <mom-icon class="RPW__view-icon" width="24" height="24" svgIcon="view"
                            @click.native="switchVisiblity('newPassword')"></mom-icon>

                        <mom-popper trigger="click" ref="popper" :arrow="false" @click.native="checkPasswordStrength">

                            <div class="RPW__password-tips m-panel">
                                <strong>Password must include</strong>
                                <ul class="RPW__password-tip-list">
                                    <li v-for="hint in passwordHints" :class="hint.satisfied ? 'satisfied' : ''">
                                        <span>{{ hint.text }}</span>
                                    </li>
                                </ul>
                            </div>

                            <mom-icon slot="anchor" class="RPW__bulb-icon" width="24" height="24"
                                :key="passwordHint" svgIcon="light-bulb"></mom-icon>
                        </mom-popper>
                    </div>

                    <mom-error v-if="!$v.newPassword.required">New password is required.</mom-error>
                    <mom-error v-else-if="!$v.newPassword.notSameAs">New password can not be same as old.</mom-error>
                    <mom-error v-else-if="!passwordCriterionSatisfied">Password criterion not satisfied.</mom-error>
                </input-container>

                <input-container :dirtyForm="$v.$dirty">
                    <label class="RPW__form-label">Confirm Password</label>

                    <div class="RPW__confirm-password">
                        <input :type="confirmPasswordFieldType" class="input__text RPW__form-input"
                            v-invalid="hasError('repeatPassword')" v-model="repeatPassword" @paste="disablePaste"
                            @input="onTextInput">

                        <mom-icon class="RPW__confirm-view-icon" width="24" height="24" svgIcon="view"
                            @click.native="switchVisiblity('confirmPassword')"></mom-icon>
                    </div>

                    <mom-error v-if="!$v.repeatPassword.sameAs">Passwords must match.</mom-error>
                    <mom-error v-if="resetPwdFailure"> {{ apiErrorMessage }} </mom-error>
                </input-container>

            <save-button type="submit" class="m-button RPW__button" :transition="buttonTransitPeriod" @state="onSaveButtonState" :state="saveState">Submit</save-button>

            </form>
        </div>

        <div v-else>
            <h3>Your password has been reset successfully. You will be automatically redirected back to the login page.</h3>
        </div>
</div>
</div>
