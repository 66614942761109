import * as R from 'ramda';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

import WithRender from './ForgotPasswordForm.html';

const BUTTON_TRANSIT_PERIOD = 1500;
const SAVE_STATE = 'saveState';

const SHOW_ERROR = R.cond([
    [R.equals(1) || R.equals(2), R.always(false)],
    [R.equals(4), R.always(true)],
    [R.T, R.always(true)]
]);

@WithRender
@Component({
    validations: {
        hint: {
            required
        }
    }
})
export default class ForgotPasswordForm extends Vue {

    public $v: any;

    @Prop({ default: 0 })
    public [SAVE_STATE]: number;

    public buttonTransitPeriod: number = BUTTON_TRANSIT_PERIOD;
    public hint: string = '';
    public showError: boolean = false;

    @Watch(SAVE_STATE)
    public onSaveStateProp() {
        this.showError = SHOW_ERROR(this.saveState);
    }

    public onSubmit() {
        this.$v.$touch();

        if (this.$v.$invalid) {
            return;
        }

        this.$emit('submit', this.hint);
    }

    public onCancel() {
        this.$emit('cancel');
    }

    public hasError(model) {
        return this.$v.$dirty && this.$v[model].$error;
    }
}
