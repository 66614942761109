import Vue from 'vue';
import Component from 'vue-class-component';

import Header from 'components/Header/Header';

/**
 * `AppComponent` is top level component.
 * It defines overall layout of the application.
 * @class AppComponent
 */
@Component({
    components: {
        'app-header': Header
    }
})
export default class AppComponent extends Vue {

}
