import * as R from 'ramda';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';

import FacebookLink from './Social/Facebook.vue';
import GoogleLink from './Social/Google.vue';
import LinkedinLink from './Social/LinkedIn.vue';

import WithRender from './Login.html';

const FORM_DOMAIN: string = window.location.origin;
const FORM_ACTION_PREFIX = '/api/oauth2';
const LOGIN_FORM_ACTION: string = FORM_ACTION_PREFIX + '/login';
const EXT_LOGIN_FORM_ACTION: string = FORM_ACTION_PREFIX + '/externallogin';
const FORM_METHOD: string = 'POST';
const FORM_ENC_TYPE: string = 'application/x-www-form-urlencoded';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please contact administrator.';

enum SOCIAL_LOGIN_METHOD {
    Google = 'Google',
    Facebook = 'Facebook',
    LinkedIn = 'LinkedIn'
}

@WithRender
@Component({
    components: {
        FacebookLink, GoogleLink, LinkedinLink
    }
})
export default class Login extends Vue {

    public returnUrl: string = '';
    public isError = false;
    public loginErrorMessage: string = '';

    public username: string = '';

    private loginForm: HTMLFormElement;

    private remainingAttempts: number = 0;

    private queryParams: any = {};

    public get invalidIdWithAttemptsMessage() {

        const attempts = isNaN(this.remainingAttempts) ? 'Few' : this.remainingAttempts;

        return `You have entered invalid details. ${attempts} more wrong attempt/s will lock your id.`;
    }

    public created() {
        this.processQueryParams();
    }

    public mounted() {
        this.loginForm = this.getLoginForm();
    }

    public onSubmit() {
        this.isError = false;

        this.loginForm.submit();
    }

    public onForgotClick() {
        this.$router.push({ name: 'ForgotPassword' });
    }

    public getUrl(provider: keyof typeof SOCIAL_LOGIN_METHOD) {

        const q = { ...this.queryParams };

        q.provider = provider;

        q.state = btoa(JSON.stringify({ IsSignUp: false }));

        const params = Object.keys(q).reduce((a, x, i, k) => {
            return q[x]
                ? a + `${x}=${q[x]}` + `${i < k.length - 1 ? '&' : ''}`
                : a;
        }, '');

        return FORM_DOMAIN + EXT_LOGIN_FORM_ACTION + `?${params}`;
    }

    private get errorMessage() {
        return R.cond([
            [R.equals(1), R.always('Invalid username/password.')],
            [R.equals(2), R.always('Invalid return url.')],
            [R.equals(3), R.always('Your account has been locked due to consecutive invalid login attempts. Please try after 30 minutes.')],
            [R.equals(4), R.always(this.invalidIdWithAttemptsMessage)],
            // tslint:disable-next-line:max-line-length
            [R.equals(5), R.always('Looks like your email is not registered with Monjin. Please sign up or login with a registered email.')],
            [R.T, () => DEFAULT_ERROR_MESSAGE]
        ]);
    }

    private getLoginForm() {
        const loginForm: HTMLFormElement = this.$refs.loginForm as HTMLFormElement;

        loginForm.action = FORM_DOMAIN + LOGIN_FORM_ACTION;
        loginForm.method = FORM_METHOD;
        loginForm.enctype = FORM_ENC_TYPE;

        return loginForm;
    }

    private processQueryParams() {
        const queryParams: Dictionary<string> = this.$route.query;

        this.username = queryParams.username;

        if (!queryParams) {
            return;
        }

        this.remainingAttempts = Number(queryParams.attempts);

        if (queryParams.errorCode) {
            this.showError(queryParams);
        }

        if (queryParams.returnUrl) {
            this.returnUrl = this.buildReturnUrl(queryParams);
            this.buildQueryParams(this.returnUrl);
        }
    }

    private showError(queryParams: Dictionary<string>) {
        this.loginErrorMessage = this.errorMessage(Number(queryParams.errorCode));
        this.isError = true;
    }

    private buildReturnUrl(queryParams: Dictionary<string>) {

        const returnUrl = queryParams.returnUrl;

        const queryStrReducer = (a, x) => a + `&${x}=${queryParams[x]}`;

        const filterKeys = ['returnUrl', 'username', 'errorCode'];

        const otherParams = Object.keys(queryParams)
            .filter((x: string) => !filterKeys.includes(x))
            .reduce(queryStrReducer, '');

        return returnUrl + otherParams;
    }

    private buildQueryParams(returnUrl: string) {
        const url = new URL(FORM_DOMAIN + returnUrl);

        this.queryParams = {
            client_id: url.searchParams.get('client_id'),
            redirect_uri: url.searchParams.get('redirect_uri'),
            response_type: url.searchParams.get('response_type'),
            scope: url.searchParams.get('scope'),
            nonce: url.searchParams.get('nonce'),
            state: url.searchParams.get('state'),
            code_challenge: url.searchParams.get('code_challenge'),
            code_challenge_method: url.searchParams.get('code_challenge_method'),
            post_logout_redirect_uri: url.searchParams.get('post_logout_redirect_uri'),
            provider: ''
        };
    }
}
