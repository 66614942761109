export const HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

const DOMAIN: string = window.location.origin;
const GET_METHOD: string = 'GET';
const POST_METHOD: string = 'POST';

const RESET_F_PWD_API = '/api/oauth2/forgot-password';
const RESET_PWD_API = '/api/oauth2/reset-password';

// FPwd indicates that this function is being used for Forgot Password workflow
export function generateResetFPwdLink(hint: string): Promise<any> {
    const url = DOMAIN + RESET_F_PWD_API;
    const headers = HEADERS;

    const body = JSON.stringify({
        Hint: hint
    });

    return fetch(url, { method: POST_METHOD, headers, body })
        .then(onSuccess)
        .catch(onError);
}

export function validateResetFPasswordId(resetPwdId: string): Promise<any> {
    const url = DOMAIN + RESET_F_PWD_API + `/${resetPwdId}`;

    const headers = HEADERS;

    return fetch(url, { method: GET_METHOD, headers })
        .then(onSuccess)
        .catch(onError);
}

export function resetForgottenPassword(resetPwdId: string, password: string): Promise<any> {
    const url = DOMAIN + RESET_F_PWD_API + `/${resetPwdId}`;

    const headers = HEADERS;

    const body = JSON.stringify({
        Password: password
    });

    return fetch(url, { method: POST_METHOD, headers, body })
        .then(onSuccess);
}

export function resetPassword(oldPassword, newPassword, userId, returnUrl) {
    const url = DOMAIN + RESET_PWD_API;

    const headers = HEADERS;

    const body = JSON.stringify({
        OldPassword : oldPassword,
        Password : newPassword,
        UserId : userId,
        ReturnUrl : returnUrl
    });

    return fetch(url, { method: POST_METHOD, headers, body });
}

function onSuccess(res: Response) {
    const errorM: string = 'Error in resetting the password.';

    if (!res) {
        throw new Error(errorM);
    }

    if (res.status >= 200 && res.status < 300) {
        return res.json();
    }

    switch (res.status) {
        case 417:
            return res.json().then((x) => {
                throw new Error(x.message);
            });

        default:
            throw new Error(errorM);
    }
}

function onError(err) {
    throw new Error(err);
}
