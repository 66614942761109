const BASE_PROTOCOL = '//';

export const DEV_URL_ORIGIN = BASE_PROTOCOL + 'localhost:11000';
export const INT_URL_ORIGIN = BASE_PROTOCOL + 'uni-int.monj.in';
export const STG_URL_ORIGIN = BASE_PROTOCOL + 'uni-stg.monj.in';
export const PROD_URL_ORIGIN = BASE_PROTOCOL + 'uni.monjin.com';

export const SDK_AUTH_PATH = '/internal/auth';

export const SDK_LOGIN_PATH = `${SDK_AUTH_PATH}/login`;
