import arrowRightSvg from './arrowRight.svg';
import lightBulbSvg from './lightBulb.svg';
import monjinLogoSvg from './monjinLogo.svg';
import viewSvg from './view.svg';

export const arrowRight = {
    name: 'arrow-right',
    svg: arrowRightSvg,
    viewBox: '0 0 24 24'
};

export const monjinLogo = {
    name: 'monjin-logo',
    svg: monjinLogoSvg,
    viewBox: '0 0 24 24'
};

export const lightBulb = {
    name: 'light-bulb',
    svg: lightBulbSvg,
    viewBox: '0 0 24 24'
};

export const view = {
    name: 'view',
    svg: viewSvg,
    viewBox: '0 0 24 24'
};
