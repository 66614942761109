import * as R from 'ramda';

import { DEV_URL_ORIGIN, INT_URL_ORIGIN, PROD_URL_ORIGIN, STG_URL_ORIGIN } from './constants';

export const isEnv = (env: string) => (hostname: string) => hostname.includes(env);

export const envDomain = R.cond([
    [isEnv('localhost'), R.always(DEV_URL_ORIGIN)],
    [isEnv('-int.'), R.always(INT_URL_ORIGIN)],
    [isEnv('-stage.'), R.always(STG_URL_ORIGIN)],
    [R.T, R.always(PROD_URL_ORIGIN)]
]);

export function hasUppercase(str: string): boolean {
    return str.toLowerCase() !== str;
}

export function hasNumber(str: string): boolean {
    return /\d/.test(str);
}

export function hasSpecialChars(str: string): boolean {
    const format = /[!@#$%^&*(),.?":{}|<>]+/;
    return format.test(str);
}

export interface PasswordHint {
    text: string;
    satisfied: boolean;
    type: string;
}

export const PASSWORD_HINTS: PasswordHint[] = [
    {
        text: '8 - 20 characters',
        satisfied: false,
        type: 'lengthCheck'
    },
    {
        text: 'At least 1 uppercase character',
        satisfied: false,
        type: 'upperCaseCheck'
    },
    {
        text: 'At least 1 number',
        satisfied: false,
        type: 'numberCheck'
    },
    {
        text: 'At least 1 special character',
        satisfied: false,
        type: 'specialCharCheck'
    }
];