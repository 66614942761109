<div class="login max-width">
    <div class="login__main">
        <img class="login__img" src="../../assets/login.png" />
    </div>

    <div class="login__sidebar">
        <form class="login__form" ref="loginForm">
            <input-container>
                <label class="login__form-label">Email</label>
                <input class="input__text login__form-input" tabindex="1" name="Username" type="text" v-model="username"
                    placeholder="example@domain.com" />
            </input-container>

            <input-container>
                <div class="login__password-label">
                    <label class="login__form-label">Password</label>
                    <router-link class="login__forgot-link" :to="{ name: 'ForgotPassword' }" tabindex="4">Forgot?
                    </router-link>
                </div>
                <input class="input__text login__form-input" name="Password" type="password" tabindex="2" />

                <mom-error v-if="isError"> {{loginErrorMessage}} </mom-error>
            </input-container>

        <!-- This hidden field is necessary to preserve the return url in the form -->
            <input type="hidden" name="ReturnUrl" :value="returnUrl" />

            <button ref="submit" type="submit" class="login__button m-button" @click="onSubmit" tabindex="3">Sign
                in</button>
        </form>

        <div class="login__social">
            <div class="login__divider">
                <hr />
                <span class="login__divider-or">or</span>
            </div>
            <div class="login__social--options">
                <google-link :iconSize="24" title="Sign in with Google"
                    :href="getUrl('Google')"></google-link>
                <facebook-link :iconSize="24" title="Sign in with Facebook"
                    :href="getUrl('Facebook')"></facebook-link>
                <linkedin-link :iconSize="24" title="Sign in with LinkedIn"
                    :href="getUrl('LinkedIn')"></linkedin-link>
            </div>
        </div>
    </div>
</div>