<div class="checkmail">
    <img class="checkmail__img" src="../../../../assets/checkEmail.png"/>

    <div class="checkmail__header">Check your email!</div>

    <div class="checkmail__msg checkmail__msg1">
        <p class="checkmail__para">We've emailed a password reset link to <span class="checkmail__txt-bold">{{ email }}</span></p>
        <p class="checkmail__para">Click the link to reset your password and get started</p>
    </div>

    <div class="checkmail__msg checkmail__msg2">
        <p class="checkmail__para checkmail__small-txt">Wrong email? Please <a class="checkmail__link" href="/forgot-password">re-enter your email address.</a></p>
        <p class="checkmail__para checkmail__small-txt">If you didn't see our email, check your span folder or <a class="checkmail__link" href="#">get troubleshooting tips</a></p>
    </div>
</div>