import { DirectiveOptions, VNodeDirective } from 'vue';

const validInput: DirectiveOptions = {

    // When element is inserted into DOM
    inserted(el: HTMLElement, binding: VNodeDirective) {
        // console.log(binding);
    },

    // Whenever containing component’s VNode has updated,
    // but possibly before its children have updated
    update(el: HTMLElement, binding: VNodeDirective) {
        if (binding.value) {
            el.classList.add('form-input--error');
        } else {
            el.classList.remove('form-input--error');
        }
    }
};

export default validInput;
