<section class="app-header">

    <div class="app-header__menu max-width">

        <!-- LOGO -->
        <a class="app-header__logo">
            <mom-icon svgIcon="monjin-logo"></mom-icon>
        </a>

        <span class="app-header__page">
            <mom-icon class="app-header__arrow-icon" svgIcon="arrow-right"></mom-icon>
            <span class="app-header__page-name">Login</span>
        </span>

    </div>

</section>
